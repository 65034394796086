import $ from 'jquery';
require('./shared/layout');

(function () {
  var pathname = window.location.pathname
  var focusedPricingCardId = null
  if (pathname.startsWith('/multistream/')) {
    focusedPricingCardId = 'multistreaming'
  } else if (pathname.startsWith('/livestreaming-cdn/')) {
    focusedPricingCardId = 'livestream_cdn'
  } else if (pathname.startsWith('/prerecorded-live-scheduler')) {
    focusedPricingCardId = 'pre_recorded_live'
  }

  if (focusedPricingCardId) {
    $('.pricing-card').each(function (i, node) {
      if (node.dataset.product && node.dataset.product !== focusedPricingCardId) {
        $(node).addClass('unfocused')
      } else {
        $(node).addClass('focused')
      }
    })
    // $('.pricing-card[data-product=' + focusedPricingCardId + ']').addClass('focused')
  }

  // data-product="multistreaming"
}());

// header section height set
(function () {
  var setFullHeight = function () {
    $('.header .header-container').css({
      // minHeight: '100%',
      // height: window.innerHeight + 'px',
      // minHeight: window.innerHeight - 60 + 'px',
    });
  };

  // / media query to check
  var landscapeMQ = '(min-height: 1800px) and (orientation: portrait)';
  // matched or not
  var isPortraitMonitor = window.matchMedia(landscapeMQ).matches;
  if (!isPortraitMonitor) {
    window.addEventListener('load', setFullHeight);
    window.addEventListener('resize', setFullHeight);
  }
})();

// @phone: moving homepage feature preview images to text block 
(function () {
  // / media query to check
  var phoneMQ = '(max-width: 600px) and (orientation: portrait)';
  // matched or not
  var isPhoneView = window.matchMedia(phoneMQ).matches;
  if (isPhoneView) {
    Array.prototype.forEach.call(document.querySelectorAll('.snap_preview_wrap'), function (n) {
      var p = n.parentNode.parentNode
      if (p) {
        var t = p.querySelector('.titles_wrap')
        if (t) {
          t.insertBefore(n, t.querySelector('.desc'))
        }
      }
    })
  }
}());

// testimonial corousal
(function () {
  $('.review_list_actions button').click(function () {
    var el = $(this);
    var reviewList = $('.review_list');
    var maginToMove = parseInt(reviewList.find('.review_item').css('width'));
    var multiplier = parseInt(el.data().direction);

    var oldMargin = parseInt(reviewList.css('marginLeft')) || 0;
    var newMargin = oldMargin + maginToMove * multiplier;

    var maxRotate = reviewList.find('.review_item').length;
    var mostMargin = maginToMove * (maxRotate - 1);
    if (newMargin >= -mostMargin && newMargin <= 0) {
      reviewList.css({ marginLeft: newMargin });
    }
  });
})();

// faq block switcher
(function () {
  var onFaqActivate = function (el) {
    el.classList.add('collapsed');
    el.setAttribute('data-active', '1');
  };

  var faqBlocks = $('.faq_block');
  faqBlocks.click(function () {
    var elthis = this;
    var prevActive = null;
    faqBlocks.each(function (i, el) {
      if (!prevActive && el.hasAttribute('data-active')) {
        prevActive = el;
      }

      if (el !== elthis) {
        el.classList.remove('collapsed');
        el.removeAttribute('data-active');
      }
    });

    if (elthis !== prevActive) {
      onFaqActivate(elthis);
    }
  });
})();